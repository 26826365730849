
import SpinnerImage from 'src/assets/svg/spinner.svg';
import classes from './styles.module.css';


function Spinner() {
    return (
        <span className={classes.loader}></span>
    )
}

export default Spinner
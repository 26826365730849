import { Users, Link, HighQuality } from "src/components/icons";

export const features = [
    {
        icon: Users,
        key: 'featrue-3',
        ar_title: "فريق محترف",
        en_title: "professional team",
        ar_text: "يقدم فريقنا الحلول التي تناسب جميع الفئات مهما كان تخصصك ستجد معنا ما يناسبك لاننا نلتزم بضمان افضل النتائج الإستثنائية لتجاوز توقعاتك وتلبية تطلعاتك .",
        en_text: "Our team provides solutions that suit all categories. Whatever your specialty, you will find with us what suits you because we are committed to ensuring the best exceptional results to exceed your expectations and meet your aspirations."
    },
    {
        icon: Link,
        key: 'featrue-2',
        ar_title: "جدير بالثقة",
        en_title: "Trustworthy",
        ar_text: "تمتع بالثقة والجودة والشفافية مع فريقنا لأننا نولي اهتماما كبيرا لرضاكم وثقتكم في خدماتنا ونجعلها أولويتنا. ولذلك نقدم مستوى عالي ومتميز من الأداء.",
        en_text: "Enjoy trust, quality and transparency with our team because we pay great attention to your satisfaction and trust in our services and make it our priority. Therefore, we provide a high and distinguished level of performance."
    },

    {
        icon: HighQuality,
        key: 'featrue-1',
        ar_title: "ما بعد البيع",
        en_title: "After sales",
        ar_text: "إبدأ معنا الأن مع افضل فريق تقني واحصل على العديد من المزايا مع خدمة ما بعد البيع والدعم الفني 24 \ 7  ،يعتمد فريقنا على خبراته المميزة التي تساعد العميل في الوصول إلى أهدافه .",
        en_text: "Start with us now with the best technical team and get many advantages with after-sales service and technical support 24/7. Our team relies on its distinguished expertise that helps the customer reach his goals."
    },

]
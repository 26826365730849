
import { ItemType } from './types';

import classes from './styles.module.css';


function Item({ item, lang }: ItemType) {
    return (
        <div className="col-12 col-lg-4">
            <div className={classes.item}>
                <div className={classes.icon}>
                    <item.icon />
                </div>
                <div className={classes.content}>
                    <h4>{lang.includes('ar') ? item.ar_title : item.en_title}</h4>
                    <p>
                        {lang.includes('ar') ? item.ar_text : item.en_text}
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Item

import React from 'react'

function HighQualuity() {
    return (
        <svg width="100" height="95" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M26.6683 9.09303C30.6888 8.77203 34.5054 7.19069 37.5748 4.5742C41.0415 1.6216 45.4463 0 50 0C54.5537 0 58.9585 1.6216 62.4252 4.5742C65.4946 7.19069 69.3112 8.77203 73.3317 9.09303C77.8719 9.45589 82.1345 11.4241 85.3552 14.6448C88.5759 17.8655 90.5441 22.1281 90.907 26.6683C91.2257 30.6872 92.807 34.506 95.4258 37.5748C98.3784 41.0415 100 45.4463 100 50C100 54.5537 98.3784 58.9585 95.4258 62.4252C92.8093 65.4946 91.228 69.3112 90.907 73.3317C90.5441 77.8719 88.5759 82.1345 85.3552 85.3552C82.1345 88.5759 77.8719 90.5441 73.3317 90.907C69.3112 91.228 65.4946 92.8093 62.4252 95.4258C58.9585 98.3784 54.5537 100 50 100C45.4463 100 41.0415 98.3784 37.5748 95.4258C34.5054 92.8093 30.6888 91.228 26.6683 90.907C22.1281 90.5441 17.8655 88.5759 14.6448 85.3552C11.4241 82.1345 9.45589 77.8719 9.09303 73.3317C8.77203 69.3112 7.19069 65.4946 4.5742 62.4252C1.6216 58.9585 0 54.5537 0 50C0 45.4463 1.6216 41.0415 4.5742 37.5748C7.19069 34.5054 8.77203 30.6888 9.09303 26.6683C9.45589 22.1281 11.4241 17.8655 14.6448 14.6448C17.8655 11.4241 22.1281 9.45589 26.6683 9.09303ZM73.1692 41.9186C74.3077 40.7398 74.9376 39.161 74.9234 37.5223C74.9092 35.8835 74.2518 34.3159 73.093 33.1571C71.9342 31.9983 70.3666 31.3409 68.7278 31.3267C67.0891 31.3125 65.5103 31.9424 64.3315 33.081L43.7499 53.6626L35.6685 45.5812C34.4897 44.4427 32.9109 43.8127 31.2722 43.8269C29.6334 43.8412 28.0658 44.4985 26.907 45.6573C25.7482 46.8161 25.0908 48.3837 25.0766 50.0225C25.0624 51.6613 25.6923 53.24 26.8308 54.4188L39.3311 66.919C40.5031 68.0908 42.0926 68.749 43.7499 68.749C45.4072 68.749 46.9967 68.0908 48.1687 66.919L73.1692 41.9186Z" fill="#20B1D3" />
        </svg>

    )
}

export default HighQualuity
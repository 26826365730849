export const services = [
    {
        "key": "service_1",
        "en_name": "web development",
        "ar_name": "تصميم وتطوير مواقع ويب",
        "en_description": "The LDW team combines creativity and technology to provide you with a website with great technical features that is characterized by fast performance, responsiveness, and ease of use, taking into account different browsers and different devices. Our motto is speed and perfection.",
        "ar_description": "يدمج فريق LDW الإبداع والتكنولوجيا ليقدم لك موقعا ذو ميزات تقنية رائعة تتميز بسرعة الأداء والإستجابة وسهولة الإستخدام مع مراعاة المتصفحات المختلفة والأجهزة المختلفة لان شعارنا السرعة والإتقان .",
        "image": "web_dev.avif"
    },
    {
        "key": "service_2",
        "en_name": "app development",
        "ar_name": " تصميم وتطوير تطبيقات الهاتف",
        "en_description": "The LDW team works on developing and designing various types of applications for companies and individual projects with an elegant and distinctive design in which we combine simplicity and modernity.",
        "ar_description": "يعمل فريق LDW  على برمجة وتصميم مختلف انواع التطبيقات للشركات والمشاريع الفردية بتصميم انيق ومميز نجمع فيه البساطة والحداثة .",
        "image": "app_dev.avif"
    },
    {
        "key": "service_3",
        "en_name": "UI / UX design",
        "ar_name": "تصميم واجهات المستخدم",
        "en_description": "The LDW team offers you the best user experience through a modern interface and attractive visual effects through which we transform your vision into a digital reality that leaves a lasting impression on your customers.",
        "ar_description": "يقدم لك فريق LDW أفضل تجربة مستخدم بواجهة عصرية ومؤثرات بصرية جذابة نحول من خلالها رؤيتك إلى واقع رقمي يترك إنطباعا دائما لعملائك .",
        "image": "ui_ux.avif"
    }
]
import classes from './styles.module.css';
import { TFunction } from 'i18next';
import { Element } from 'react-scroll';

import { Title } from 'src/components';
import { features } from 'src/data/features';
import Item from './Item';
import { FeatureType } from './types';



function Features(props: { t: TFunction, lang: string }) {
    return (
        <Element as='section' name="features" className={classes.features}>
            <div className="container">
                <Title smTitle={props.t("features.title.sm")} lgTitle={props.t("features.title.lg")} />
                <div className={` ${classes.itemsCon} row`}>
                    {features.map((item: FeatureType) => {
                        return <Item key={item.key} item={item} lang={props.lang} />
                    })}
                </div>
            </div>
        </Element>
    )
}

export default Features
import { useState, useEffect, useCallback } from 'react';
import { TFunction } from 'i18next';
import { Link } from 'react-scroll';

import classes from './styles.module.css';
import { Menu, XMark, ChevronDown } from 'src/components/icons';

import logo from 'src/assets/images/logo.svg';
import bgLogo from 'src/assets/images/bg_logo.svg';

const navItems = [
    { name: 'nav.services', path: '#', key: 'nav-1', scrollTo: "services" },
    { name: 'nav.about', path: '#', key: 'nav-2', scrollTo: "aboutUs" },
    { name: 'nav.features', path: '#', key: 'nav-3', scrollTo: "features" },
    { name: 'nav.contact', path: '#', key: 'nav-4', scrollTo: "contactUs" },
]


export default function Nav({ t, i18n }: { t: TFunction, i18n: any }) {

    const [showMenu, setShowMenu] = useState(false);

    const [navBG, setNavBG] = useState(false);

    const navBackgroundHandler = (hasWindow: boolean) => {
        if (hasWindow && window.scrollY >= 64) {
            setNavBG(true);

        } else {
            setNavBG(false);
        }
    }

    const hideMenuHandler = useCallback(() => setShowMenu(false), [])


    const langChangeHandler = (e: any) => {
        e.preventDefault();
        i18n.changeLanguage(i18n.language.includes('ar') ? 'en' : 'ar');
    };


    useEffect(() => {
        const hasWindow = typeof window !== 'undefined';
        navBackgroundHandler(hasWindow);

        if (hasWindow) {
            window.addEventListener('scroll', () => navBackgroundHandler(hasWindow))
            return () => {
                window.removeEventListener('scroll', () => navBackgroundHandler(hasWindow))
            }
        }
    }, []);


    return (<header className={`${classes.header} ${navBG ? classes.navBg : ''}`}>
        <nav className={`${classes.nav} container-fluid`}>
            <div className={classes.nav__data}>
                <a href="/" className={classes.nav__logo}>
                    <img width={80} height={23.5} src={navBG ? bgLogo : logo} alt={i18n.language.includes('ar') ? "ساحر ليبيا التقني" : "LDW"} />
                </a>

                <div className={`${classes.nav__toggle} ${showMenu ? classes.show__icon : ''} `} >
                    <Menu className={`${classes.nav__burger} ${showMenu ? classes.hide__burger__icon : ''} `}
                        onClick={() => {
                            setShowMenu(true);
                        }} />
                    <XMark className={`${classes.nav__close} ${showMenu ? classes.show__close__icon : ''} `}
                        onClick={() => {
                            setShowMenu(false);
                        }}
                    />
                </div>
            </div>

            {/* <!-- =============== NAV MENU =============== --> */}

            <div onClick={() => setShowMenu(false)} className={`${classes.nav__menu} ${showMenu ? classes.show__menu : ''}`} >
                <ul className={classes.nav__list}>
                    {/* <!--=============== DROPDOWN 1 ===============--> */}


                    {navItems.map((item: any) => {
                        return item.sub_menu ?
                            <li key={item.key} className={classes.dropdown__item}>
                                <div className={classes.nav__link}>
                                    {t(item.name)}
                                    <ChevronDown className={classes.dropdown__arrow} />

                                </div>

                                <ul className={classes.dropdown__menu}>
                                    {
                                        item.sub_menu.map((sub_item: any) => {
                                            return sub_item.sub_menu ?

                                                <li className={classes.dropdown__subitem} >
                                                    {/* <!--=============== DROPDOWN SUBMENU ===============--> */}

                                                    <div className={classes.dropdown__link}>
                                                        {t(sub_item.name)}
                                                    </div>

                                                    <ul className={classes.dropdown__submenu}>

                                                        {sub_item.sub_menu.map((sub_sub_item: any) => {
                                                            return <li>
                                                                {sub_sub_item.scrollTo
                                                                    ?
                                                                    <Link onClick={hideMenuHandler} href={`#${sub_sub_item.scrollTo}`}
                                                                        to={sub_sub_item.scrollTo} smooth={true} duration={600} className={classes.dropdown__sublink}>
                                                                        {t(sub_sub_item.name)}
                                                                    </Link>
                                                                    :
                                                                    <a href={sub_sub_item.path} className={classes.dropdown__sublink}>
                                                                        {t(sub_sub_item.name)}
                                                                    </a>
                                                                }

                                                            </li>
                                                        })}
                                                    </ul>

                                                </li>
                                                :
                                                <li>
                                                    {sub_item.scrollTo ?
                                                        <Link onClick={hideMenuHandler} href={`#${sub_item.scrollTo}`} to={sub_item.scrollTo} smooth={true} duration={600} className={classes.dropdown__link}>
                                                            {t(sub_item.name)}
                                                        </Link>
                                                        :
                                                        <a href={sub_item.path} className={classes.dropdown__link}>
                                                            {t(sub_item.name)}
                                                        </a>
                                                    }

                                                </li>
                                        })
                                    }
                                </ul>
                            </li>

                            : <li key={item.key}>
                                {item.scrollTo
                                    ?
                                    <Link onClick={hideMenuHandler} href={`#${item.scrollTo}`} to={item.scrollTo} smooth={true} duration={600} className={classes.nav__link}>
                                        {t(item.name)}
                                    </Link>
                                    :
                                    <a href={item.path} className={classes.nav__link}>
                                        {t(item.name)}
                                    </a>
                                }

                            </li>

                    })}
                    <li>
                        <a aria-current={'page'}
                            onClick={langChangeHandler}
                            href='lang'
                            className={`${classes.nav__link} ${i18n.language.includes('ar') ? "en-f" : "ar-f"}`} >
                            {i18n.language.includes('ar') ? "EN" : "العربية"}
                        </a>
                    </li>
                </ul>
            </div>
        </nav >
    </header >
    )
}

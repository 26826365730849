import React from 'react';
import { TFunction } from 'i18next';
import { toast } from 'react-toastify';
import { Element } from 'react-scroll';

import classes from './styles.module.css';
import { Title } from 'src/components';
import ContactForm from './Form';
import { Phone, Whatsapp, Envelope, Facebook } from 'src/components/icons';


const copyToClipBoard = async (copyMe: string) => {
    try {
        await navigator.clipboard.writeText(copyMe);
        return true;
    } catch (err) {
        return false
    }
};

function Contact({ t, lang }: { t: TFunction, lang: string }) {


    const phoneCopyHandler = async () => {
        const copied = await copyToClipBoard('+218912459498');
        if (copied) {
            const text = lang === 'ar' ? 'تم النسخ 👍' : 'Copied 👍'
            toast(text, {
                style: { width: 120 },
                position: lang === 'ar' ? "bottom-right" : "bottom-left",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        } else {
            console.log("Error");
        }
    }

    const linkHandler = (e: React.MouseEvent<HTMLElement>, url: string) => {
        window.open(url, '_blank');
    };

    return (
        <Element name={'contactUs'} className={classes.contact}>
            <div className={`container ${classes.container}`}>
                <div className='row'>
                    <div className='col-12 mb-5'>
                        <Title smTitle={t('contact.title.sm')} lgTitle={t('contact.title.lg')} lgTitleColor='#fff' smTitleColor='#20B1D3' />
                    </div>

                    <div className='mb-5'>

                    </div>
                    <div className='col-lg-5 col-12'>
                        <div className={classes.info}>
                            <div className={classes.infoItem} onClick={phoneCopyHandler}>
                                <div className={classes.infoItemIcon}>
                                    <Phone />
                                </div>
                                <div className={classes.infoContent}>
                                    <h4>  {t("contact.info.phone_number.name")} </h4>
                                    <p> {t("contact.info.phone_number.value")} </p>
                                </div>
                            </div>
                            <div className={classes.infoItem} onClick={(e) => linkHandler(e, "https://wa.me/+218912459498")}>
                                <div className={classes.infoItemIcon}>
                                    <Whatsapp />
                                </div>

                                <div className={classes.infoContent}>
                                    <h4> {t("contact.info.whatsapp.name")} </h4>
                                    <p>
                                        {t("contact.info.whatsapp.value")}
                                    </p>
                                </div>
                            </div>
                            <div className={classes.infoItem} onClick={(e) => linkHandler(e, "mailto:libyadigitalwizard@gmail.com")}>
                                <div className={classes.infoItemIcon} >
                                    <Envelope />
                                </div>

                                <div className={classes.infoContent}>
                                    <h4> {t("contact.info.email.name")} </h4>
                                    <p className={classes.en_p}> {t("contact.info.email.value")}</p>
                                </div>
                            </div>
                            <div className={classes.infoItem} onClick={(e) => linkHandler(e, "https://www.facebook.com/profile.php?id=100090640029673")}>
                                <div className={classes.infoItemIcon}>
                                    <Facebook />
                                </div>

                                <div className={classes.infoContent}>
                                    <h4> {t("contact.info.facebook.name")} </h4>
                                    <p> {t("contact.info.facebook.value")}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-7 col-12'>
                        <div className={classes.form}>
                            <ContactForm t={t} lang={lang} />
                        </div>
                    </div>
                </div>
            </div >
            <div className={`${classes.overlay}`}>
            </div>
        </Element >
    )
}

export default Contact
import classes from './styles.module.css';
import ArrowLongDown from 'src/components/icons/ArrowLongDown';
import { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { Link } from 'react-scroll';

import img from 'src/assets/images/hero.avif';
import img_ar from 'src/assets/images/hero_ar.avif';


function Hero({ t, lang }: { t: TFunction, lang: string }) {
    return (
        <section className={classes.hero}>
            <div className={classes.content}>
                <h1 className='text-4xl'>
                    {t('hero.title')}
                </h1>
                <p>
                    {t('hero.text')}
                </p>
                <Link to={'contactUs'} href='contact-us' smooth={true} duration={600}
                    className={`${classes.contactBtn} btn`}>
                    {t('hero.btn')}
                </Link>
            </div>

            <div className={classes.imgCon}>
                <img src={lang.includes('ar') ? img_ar : img} width={550} height={'fit-content'} alt={lang.includes('ar') ? "ساحر ليبيا التقني" : "Libya Digital Wizard"} />
            </div>

            <Link to={'services'} href='explore' smooth={true} duration={600} className={classes.btn}>
                <div>
                    {t('hero.explore')}
                </div>
                <div>
                    <ArrowLongDown />
                </div>

            </Link>

        </section>

    )
}


function SmHero({ t }: { t: TFunction }) {
    return (
        <section className={classes.sm__hero}>
            <div className={classes.sm__content}>
                <h1 className='text-4xl'>
                    {t('hero.title')}
                </h1>
                <p>
                    {t('hero.text')}
                </p>
                <Link to={'contactUs'} href='contact-us' smooth={true} duration={600}
                    className={`${classes.contactBtn} btn`}>
                    {t('hero.btn')}
                </Link>
            </div>
        </section>

    )
}


export default ({ t, lang }: { t: TFunction, lang: string }) => {

    // Get Window Width ==================
    const getWidth = (hasWindow: boolean) => {
        const width = hasWindow ? window.innerWidth : 0;
        return width
    }

    const [width, setWidth] = useState(0);


    useEffect(() => {
        const hasWindow = typeof window !== 'undefined';
        setWidth(getWidth(hasWindow));

        if (hasWindow) {
            const handleResize = () => {
                setWidth(getWidth(hasWindow));
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);


    return width >= 1119 ? <Hero t={t} lang={lang} /> : <SmHero t={t} />

}

import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';

import Nav from 'src/layouts/nav/Nav';
import Footer from 'src/layouts/footer/Footer';


function App() {
    const { t, i18n } = useTranslation();
    return (
        <div className={`main__con ${i18n.language.includes('ar') && 'ar'}`}>
            <Nav t={t} i18n={i18n} />
            <Outlet />
            <Footer t={t} lang={i18n.language} />
            <ToastContainer closeButton={false} />
        </div>
    )
}


export default App;

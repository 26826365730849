import React from 'react'
import { ItemType } from './types'

import classes from './styles.module.css';



function Item({ item, reversed, lang }: ItemType) {
    const img = require(`src/assets/images/services/${item.image}`)
    return (
        <div className={[classes.item, reversed ? classes.reversed : ""].join(' ')}>
            <div className={classes.imgCon}>
                <img src={img} loading='lazy' width={340} height={340} alt={lang.includes('ar') ? item.ar_name : item.en_name} />
            </div>
            <div className={classes.content}>
                <h2>{lang.includes('ar') ? item.ar_name : item.en_name}</h2>
                <p>
                    {lang.includes('ar') ? item.ar_description : item.en_description}
                </p>
            </div>
        </div>
    )
}

export default Item
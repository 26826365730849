import React from 'react'

function Users() {
    return (
        <svg width="120" height="90" viewBox="0 0 120 84" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M18 36C24.6187 36 30 30.6187 30 24C30 17.3813 24.6187 12 18 12C11.3813 12 6 17.3813 6 24C6 30.6187 11.3813 36 18 36ZM102 36C108.619 36 114 30.6187 114 24C114 17.3813 108.619 12 102 12C95.3812 12 90 17.3813 90 24C90 30.6187 95.3812 36 102 36ZM108 42H96C92.7 42 89.7188 43.3313 87.5437 45.4875C95.1 49.6313 100.463 57.1125 101.625 66H114C117.319 66 120 63.3187 120 60V54C120 47.3812 114.619 42 108 42ZM60 42C71.6062 42 81 32.6063 81 21C81 9.39375 71.6062 0 60 0C48.3937 0 39 9.39375 39 21C39 32.6063 48.3937 42 60 42ZM74.4 48H72.8438C68.9437 49.875 64.6125 51 60 51C55.3875 51 51.075 49.875 47.1562 48H45.6C33.675 48 24 57.675 24 69.6V75C24 79.9688 28.0312 84 33 84H87C91.9688 84 96 79.9688 96 75V69.6C96 57.675 86.325 48 74.4 48ZM32.4563 45.4875C30.2813 43.3313 27.3 42 24 42H12C5.38125 42 0 47.3812 0 54V60C0 63.3187 2.68125 66 6 66H18.3563C19.5375 57.1125 24.9 49.6313 32.4563 45.4875Z" fill="#20B1D3" />
        </svg>

    )
}

export default Users




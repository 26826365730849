
import React from 'react'

function Link() {
    return (
        <svg width="90" height="90" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M63.7915 36.2093C75.4609 47.8907 75.3007 66.6191 63.8618 78.1214C63.8403 78.1448 63.815 78.1702 63.7915 78.1937L50.6665 91.3187C39.0903 102.895 20.2565 102.893 8.68213 91.3187C-2.89404 79.7445 -2.89404 60.9085 8.68213 49.3343L15.9294 42.087C17.8513 40.1652 21.161 41.4425 21.2603 44.1585C21.3868 47.6198 22.0075 51.0974 23.1528 54.4556C23.5407 55.5927 23.2636 56.8505 22.414 57.7001L19.8579 60.2562C14.3841 65.73 14.2124 74.6429 19.6323 80.1702C25.1058 85.7521 34.1023 85.7853 39.6177 80.2698L52.7427 67.1468C58.2487 61.6407 58.2257 52.7411 52.7427 47.2581C52.0198 46.5366 51.2917 45.9761 50.723 45.5845C50.3206 45.3082 49.9884 44.9417 49.7528 44.5142C49.5172 44.0867 49.3848 43.6101 49.3661 43.1224C49.2888 41.0585 50.02 38.9318 51.6509 37.3009L55.763 33.1886C56.8413 32.1103 58.5329 31.9778 59.7833 32.8505C61.2153 33.8504 62.5565 34.9743 63.7915 36.2093ZM91.3179 8.68155C79.7435 -2.89307 60.9097 -2.89463 49.3335 8.68155L36.2085 21.8066C36.1851 21.83 36.1597 21.8554 36.1382 21.8788C24.6995 33.3812 24.5392 52.1095 36.2085 63.7909C37.4434 65.0258 38.7846 66.1497 40.2165 67.1495C41.4669 68.0222 43.1587 67.8896 44.2368 66.8114L48.3489 62.6991C49.9798 61.0683 50.711 58.9415 50.6337 56.8777C50.615 56.3899 50.4826 55.9133 50.247 55.4858C50.0114 55.0584 49.6792 54.6918 49.2769 54.4155C48.7081 54.0239 47.98 53.4634 47.2571 52.7419C41.7741 47.2589 41.7511 38.3593 47.2571 32.8532L60.3821 19.7302C65.8976 14.2148 74.8939 14.248 80.3675 19.8298C85.7874 25.3571 85.6159 34.27 80.1419 39.7439L77.5859 42.2999C76.7363 43.1495 76.4591 44.4073 76.847 45.5445C77.9923 48.9027 78.613 52.3802 78.7396 55.8415C78.839 58.5575 82.1486 59.8349 84.0704 57.913L91.3177 50.6657C102.894 39.0917 102.894 20.2558 91.3179 8.68155Z" fill="#20B1D3" />
        </svg>

    )
}

export default Link
import classes from './styles.module.css';
import { services } from 'src/data/services';
import { ServiceType } from './types';
import { Title } from 'src/components';
import Item from './Item';
import { TFunction } from 'i18next';
import { Element } from 'react-scroll';

export default function Services(props: { t: TFunction, lang: string }) {

    return (
        <Element name="services" as='section' className={`${classes.services__section} container px-lg-5`}>
            <div>
                <Title lgTitle={props.t("services.title.lg")} smTitle={props.t("services.title.sm")} />
            </div>
            <div className={classes.services}>
                {services.map((item: ServiceType, index) => {
                    return <Item lang={props.lang} key={item.key} item={item} reversed={index % 2 === 0 ? true : false} />
                })}
            </div>
        </Element>

    )
}

import { Routes, Route } from 'react-router-dom';



import './setup/i18n/i18n';

import Layout from 'src/layouts/Layout';
import { Home } from 'src/pages';
import { Page404 } from 'src/components';


const routes = [
	{
		path: '/',
		component: Home,
		label: 'Home',
	},
];

function App() {
	return (<Routes>
		<Route path="/" element={<Layout />}>
			<Route index element={<Home />} />
			<Route path="*" element={<Page404 />} />
		</Route>
	</Routes>
	);
}

export default App;


import { TFunction } from 'i18next';
import classes from './styles.module.css';
import { Facebook, Instagram } from 'src/components/icons';

export default function Footer({ t, lang }: { t: TFunction, lang: string }) {

    return (<footer className={classes.footer}>
        <div className='container'>
            <div className='row'>
                <div className='col-12 pt-1 mb-1'>
                    <div className={classes.icons}>
                        <a aria-label={lang.includes("ar") ? " صفحتنا على فيسبوك" : "Our facebook page"} href='https://www.facebook.com/profile.php?id=100090640029673'>
                            <Facebook />
                        </a>
                        <a aria-label={lang.includes("ar") ? "حسابنا على انستغرام" : "Our account on Instagram"} href='https://www.instagram.com/libyadigital'>
                            <Instagram />
                        </a>
                    </div>
                </div>
                <div className='col-12 mt-2'>
                    <div className={classes.copyright}>
                        {t('footer.copyright')}

                        <span className='en-f mx-1'>
                            Libya Digital Wizard
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </footer >
    )
}

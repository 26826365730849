import classes from './styles.module.css';
import { Title } from 'src/components';
import { TFunction } from 'i18next';
import { Element } from 'react-scroll';
import img from 'src/assets/images/about.avif';

function About(props: { lang: string, t: TFunction }) {
    const text: any = {
        ar: `
        فريق  ساحر ليبيا التقني هو فريق يضم العديد من الخبراء في مجال تكنولوجيا المعلومات ،كما يتميز فريقنا بالعديد من الخبرات الرقمية المختلفة منها برمجة مواقع الويب ،تطبيقات الهاتف ،تصميمات السوشل ميديا ،الهوية البصرية والتسويق الرقمي وغيرها  هدفنا الارتقاء بمجالنا بدمج الجودة والعصرية وسهولة الاستخدام  انضم إلينا في طليعة التكنولوجيا الرقمية ، حيث التميز ليس مجرد هدف بل معيار.
    `,
        en: `
        Libya Digital Wizard  Team is a team that includes many experts in the field of information technology. Our team is also distinguished by many different digital experiences, including website programming, phone applications, social media designs, visual identity, digital marketing, and others. Our goal is to advance our field by integrating quality, modernity, and ease of use. Join us. At the forefront of digital technology, where excellence is not just a goal but a standard.
    `
    }

    return (
        <Element name="aboutUs" className={classes.about}>
            <div className='container'>
                <div className={classes.imgCon}>
                    <img src={img}
                        width={390}
                        height={390}
                        loading='lazy'
                        alt={props.t("about.title.sm")} />
                </div>
                <div className={classes.content}>
                    <Title smTitle={props.t("about.title.sm")} lgTitle={props.t("about.title.lg")} align='start' />
                    <p>
                        {text[props.lang]}
                    </p>
                </div>

            </div>
            <div className={classes.overlay}>

            </div>
        </Element>
    )
}

export default About
import React from 'react'
import Base from "./Base";


function ChevronDown(props: any) {
    return (
        <Base props={props}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>

        </Base>

    )
}

export default ChevronDown;
import { Hero, Services, About, Features, Contact } from './components';
import { useTranslation } from "react-i18next";


export default function () {
    const { t, i18n } = useTranslation(['home']);

    return (<div>
        <Hero t={t} lang={i18n.language} />
        <Services lang={i18n.language} t={t} />
        <About t={t} lang={i18n.language} />
        <Features t={t} lang={i18n.language} />

        <Contact t={t} lang={i18n.language} />

    </div>
    )
}

